import '../home/index.scss';
import shapes from "../../assets/shapes.svg";
import { Link } from 'react-router-dom';
import logo from "../../assets/logo3 (1).png";
import React, { useEffect } from 'react';
import { getTokens } from '../../services/Http';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

export default function HomeComponent() {


    const navigate = useNavigate();

    const openToat = () =>{
        toast.success('Bạn phải đăng nhập để sử dụng tính năng này',{hideProgressBar: true})
    }

    useEffect(() => {
        const token = getTokens();
        if(token.accessToken){
            navigate('/user')
        }
    }, []);

		useEffect(() => {
			const token = getTokens();
			if(token.accessToken){
				return;
			}
		}, []);
	return (
        <div>
            <ToastContainer />
            <div className='header'>
                <img className="image_logo" src={logo} alt="Logo"/>
                <div className='right_header'>
                    <Link to='/login'>
                        <button className="grey button login_button" to='/login'>Đăng Nhập</button>
                    </Link>
                    <Link to='/register'>
                        <button className="yellow button register_button" to ='/register'>Đăng Ký</button>
                    </Link>
                </div>       
            </div>
            <div className='container'>
                <div className='content-left'>
                    <div className='content-text'>
                        <h1>Trực quan, bảo mật ...</h1>
                        <p>Nền tảng Rút gọn link hỗ trợ đắc lực cho các marketer giúp Rút gọn link, 
                            Bọc link, thống kê lượt truy cập theo thời gian thực, hoàn toàn miễn phí... 
                            đẹp nhất và dễ nhớ nhất.
                        </p>
                    </div>
                    <div className='content'>
                    <input type="text" name="url"/>
                    <button className="grey button login_button" to='/login' onClick={openToat}>Rút gọn</button>
                    </div>
                </div>
                <div className='content-right'>
                    <div className='cart'>
                        <div className='url-cart'>
            
                        </div>
                        <div className='access-times'>

                        </div>
                    </div>
                    <img className="image" src={shapes} alt="Logo" />
                </div>
            </div>
        </div>
	)
}
