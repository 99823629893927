import "../login/login.scss";
import logo from "../../assets/logo3 (1).png";
import React, { useState, useContext } from 'react';
import OtpInput from 'react-otp-input';
import AppContext from '../context/AppContext';
import { registerUserOtp } from '../../services/Api';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';



export default function OTPComponent() {
	const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const state = useContext(AppContext);
    const sentOTP = ()=>{
        const data = {...state.register, clientOTP: otp}
        registerUserOtp(data).then((res)=>{
            navigate('/user')
            localStorage.setItem('accessToken', res.accessToken);
            localStorage.setItem('refreshToken', res.refreshToken);
			localStorage.setItem('userId', res.data.userId);
            state.setUser((user) =>({ auth: true , refreshToken: res.refreshToken, accessToken: res.accessToken}));
        })
        .catch((err) => {
            toast.error(err.response.data.description,{hideProgressBar: true})
        });
    }
	return (
		<div className="login_container">
            <ToastContainer />
			<div className="login_content">
                <Link to={`/`}>
				    <img className="image_logo" src={logo} alt="Logo" />
				</Link>
				<div className="welcom_text">
				Chào mừng bạn đến với RutGonLink
				</div>
                <OtpInput
                    inputType="tel"
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                        width: "3rem",
                        height:"3rem",
                        fontSize: "2rem",
                        borderRadius:" 4px",
                        border:" 1px solid rgba(0, 0, 0, .3)",
                        outline:"none"
                    }}
                    containerStyle={{
                        justifyContent: "space-between",
                    }}
                    renderSeparator={
                        <span
                          style={{
                            fontSize: "7px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {" "}
                        </span>
                      }
                />
				<button className="yellow_button" onClick={sentOTP}>Tiếp theo</button>
			</div>
		</div>
	)
}