import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/app/App';
import { AppProvider } from '../src/pages/context/AppContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
		<GoogleOAuthProvider clientId="135192936621-lvj8tmmu8ktgaen9ojckvg09e89niui6.apps.googleusercontent.com">
			<AppProvider>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<App />
				</LocalizationProvider>
			</AppProvider>
		</GoogleOAuthProvider>
  </React.StrictMode>
);