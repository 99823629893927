import React, { useState, useEffect  } from 'react';
import Moment from 'react-moment';
import { getUrl } from '../../services/Api';
import '../../pages/links/index.scss'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export default function LinksComponent() {

	const [listLinks, setListLinks]= useState([]);

    const [params, setParams] = useState({limit: 10, page:1});

    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    const handlePagination =  (page) => {
        setPageNumber(page)
        const paramsUrl = {
            page: page,
            limit: 10
        }
        getUrl(paramsUrl).then(res => {
			setListLinks(res.data);
		}).catch(err => console.log(err));
    }

	const getListUrl =() =>{
		getUrl(params).then(res => {
			setListLinks(res.data);
            const paginationArray = Array.from({length: res.last_page}, (v, k) => k+1); 
            setPagination(paginationArray);
		}).catch(err => console.log(err));
	}

    const copyUrl = (id) => {
        let copyText = document.getElementById(`${id}short`);
        let textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        toast.success('Sao chép thành công',{hideProgressBar: true})
    }

    const LinkItem = ({link}) => (
        <div className='link' key= {link.id}>
            <Link to={`/links/${link.id}`}>
                <div className='url-title'>
                        <strong>{link.Description}</strong>
                        <small>
                                <Moment format="DD/MM/YYYY">
                                        {link.RecordModified}
                                </Moment>
                        </small>
                </div>
                <div className='url-infor'>
                        <div className='long-url'>
                                <img src={'https://s2.googleusercontent.com/s2/favicons?domain='+link.LongURL} alt="Logo" />
                                <span>
                                        {link.LongURL}
                                </span>
                        </div>
                        <div className='short-url'>
                            <span id={`${link.id}short`}>{link.ShortURL}</span>
                        </div>
                        <div className='detail-btn'>
                            <span className='count-redirect'>
                                Lượt truy cập: {link.CountRedirect}
                            </span>
                            <span>
                                Xem chi tiết
                            </span>
                        </div>
                </div>
            </Link>
            <div className='copyboard' onClick={() => copyUrl(link.id)}>
                            <svg width="20px" height="20px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                                        fill="#FFFFFF"
                                    />
                            </svg>
            </div>
        </div>
    )

	useEffect(() => {
        getListUrl();
	}, []);

	return (
		<div className='list-wrapper'>
            <ToastContainer/>
		<div className='title'>Danh sách liên kết gần đây</div>
			<div className='list-links'>
					{listLinks.map(item => (
						<LinkItem
						 link ={item}
						/>
						))}                 
			 </div>
             <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous" onClick={() =>handlePagination(pageNumber-1)}>
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>
                    {pagination.map(item => (
                        <li className="page-item"><a className="page-link" href="#" onClick={() =>handlePagination(item)}>{item}</a></li>
                    ))}
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next" onClick={() =>handlePagination(pageNumber+1)}>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                    </li>
                </ul>
            </nav>
	</div>
	);
}