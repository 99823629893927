import axios from "axios";
import { refreshToken } from './Api';

const Http = axios.create({
    baseURL: 'https://api.tin247.website/',
    // baseURL: 'http://localhost:3000/',
});

export const getTokens = () =>{
    const data = { accessToken: localStorage.getItem('accessToken'),refreshToken: localStorage.getItem('refreshToken')}
	return data;
}

export const getUser = () =>{
	return {
		userId: localStorage.getItem('userId'),
	};
}

const authInterceptor = async (request) => {

    const  tokens  = getTokens();
  
    if (tokens) {
      if (request && request.headers) {
        request.headers.Authorization = `Bearer ${tokens.accessToken}`;
      }
      return request;
    }
  
    if (!tokens) {
      // TODO: handle when UNAUTHORIZED;
      // return Promise.reject(ApiStatusCodes.UNAUTHORIZED);
      return request;
    }
  
    return request;
  };
const responseInterceptor = async (response) => {
    const tokens = getTokens();
    console.log('status', response.status)
    if(response.status === 902){
        refreshToken(tokens.refreshToken).then((res) => {
            console.log('token',res);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    return response.data;
  };

Http.interceptors.response.use(responseInterceptor);
Http.interceptors.request.use(authInterceptor);
export default Http;