import { useParams } from "react-router-dom"
import '../../pages/link-detail/index.scss'
import React, {useEffect , useState, useContext} from 'react';
import { LineChart } from '../line-chart/index.js';
import logo from "../../assets/logo3 (1).png";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from 'moment';
import { getUrlDetail , putUrl} from '../../services/Api';
import { getTokens, getUser } from "../../services/Http";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ToastContainer, toast } from 'react-toastify';
import AppContext from '../../pages/context/AppContext.js';
import { io } from 'socket.io-client';



export default function LinkDetailComponent(){
    const state = useContext(AppContext);
	const startDate = moment(new Date()).format('YYYY-MM-DD');
	const [dataChart, setDataChart] = useState([]);
    const [dataLinks, setDataLinks] = useState([]);
    const [image, setImage] = useState('');
    const [data, setData]=useState([]);
	const user = getUser();
	const [value, setValue] = useState(moment(startDate));
    const hanldeFile = (e) => {
		setImage(e.target.files[0]);
	}
    const schema = yup.object().shape({
		URL: yup  
			.string()      
			.max(2048)
			.matches(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/, 
						'URL không đúng định dạng')  
			.required(),
		domain: yup
                .string() ,
		Title: yup.string().max(200),
		Description: yup.string().max(200),
        image: yup.string().max(200)
		});

	const form = useForm({
		resolver: yupResolver(schema),
        defaultValues: data
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
        reset

	} = form;

    const token = getTokens();
    const socket = io('https://api.tin247.website', {
        autoConnect: false,
          extraHeaders: {
              token: 'Bearer ' + `${token.accessToken}`
          }
    });

    const getBase64 = async (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				resolve(reader.result)
			}
			reader.onerror = reject
		})
	}
    const getUrlForm =() =>{
        getUrlDetail(linkId).then((res) => {
            setData(res)
        })
    }

	const handleSubmitForm = async (data) =>{
        delete data.file;
        let body;
        if(image){
            await getBase64(image) // `file` your img file
				.then(res =>{
					let imageBase64 = (res).split(',')[1];
					let binary = atob(imageBase64);
                    body= {...data, image: binary};
                    
				}) // `res` base64 of img file
				.catch(err => {
                    console.log(err);
                }) 
        }else{
            body = data;
        }   
        putUrl(body, linkId).then(res => {
            toast.success('Cập nhật thành công',{hideProgressBar: true})
        })
        .catch(err => toast.error(err.response.data.description,{hideProgressBar: true}));
	}

	const {linkId} = useParams()

	function connect() {
		socket.connect();
	}
	function disconnect() {
		socket.disconnect();
	}
	
	const getGetDataChart =  async () =>{
		socket.emit(user.userId + '_TotalClickById', {id:linkId, startDate:moment(value).format('YYYY-MM-DD')});
		socket.on(user.userId + '_TotalClickById', (data) => {
			setDataChart(data[0].Hours);
		})
		socket.on(`${user.userId}_ClickByIdNow` , data => {
			if(data.click){
				socket.emit(user.userId + '_TotalClickById', {id:linkId, startDate:moment(value).format('YYYY-MM-DD')});
				socket.on(user.userId + '_TotalClickById', (data) => {
					setDataChart(data[0].Hours);
				})
			}
		})
	}

	const getClickByTime = () =>{
		socket.emit(user.userId + '_listIPs', {id:linkId});
		socket.on(user.userId + '_listIPs', (data) => {
            // let array;
            // if(data.length < 20){
            //     array = Array.from({ length: 20 - data.length }, (_, i) => {
            //     return item;
            //     });
            // }
            // console.log(data);
            // let dataClick = data.concat(array);
            setDataLinks([...data]);
		})
	}

    const getClickNow = () =>{
        socket.emit(user.userId + '_IPNow', {id:linkId});
		socket.on(user.userId + '_IPNow', (ip) => {
            getClickByTime();
		})
    }

    const logOut = () => {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem('userId');
        state.setUser((user) =>({ auth: false , refreshToken: '', accessToken: ''}));
    }

	useEffect(() => {
		connect();
		getGetDataChart();
		return () => {
        disconnect();
        };
	}, [value]);

    useEffect(() => {
		getUrlForm();
	}, []);

    useEffect(() => {
        getClickNow();
	},[dataLinks]);


    useEffect(() => {
        getClickByTime();
        // return () => {
        //     disconnect();
        //     };
	}, [dataLinks]);

	return (
		<div className="wrapper-detail">
            <ToastContainer />
            <div className='header-detail'>
                <Link to={`/`}>
				<img className="image_logo" src={logo} alt="Logo" />
				</Link>
                <div className='right-header'>
                    <div className='content-left'>
                        <Link to='/user'>
                            <span className="btn-header" to='/user'>Bảng điều khiển</span>
                        </Link>
                        <Link to='/links'>
                            <span className="btn-header" to ='/links'>Danh sách links</span>
                        </Link>
                    </div>
                    <Link to='/' onClick={logOut}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out align-middle me-1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
						<span>Đăng xuất</span>
					</Link>
                </div>       
            </div>
			<div className='wrapper-content'>
                <div className="realtime-click">
                    <div className='px-4 header-label'>Hoạt động hiện tại</div>
                    <div className="header-title">
                        <table>
                            <thead>
                                <tr className='table-title'> 
                                    <th className='address'>Địa chỉ IP</th>
                                    <th className='location'>Vị trí</th>
                                    <th className='app'>Ứng dụng</th>
                                    <th className='time'>Thời gian</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataLinks.map((item, index) => (
                                    index === 20 ? <tr></tr> :
                                    <tr className='table-text'>
                                        <td className='ip-address'><span title={item.IpAddress}>{item.IpAddress}</span></td>
                                        <td className="location"><span>{item.Location}</span></td>
                                        <td className='app'>{item.App}</td>
                                        <td>{item.Record_Create }</td>
                                    </tr>
                                ))}
                            </tbody>     
                        </table>
                    </div>
                </div>
                <div className="chart">
                    <div className='px-4 header-label'>
											<div>
												 Biểu đồ				
											</div>
											<div className="date-time">
												<DatePicker value={value} 
													onChange={(newValue) => setValue(newValue)} 
													slotProps={{ textField: { size: 'small' } }} 
												/>
											</div>				
										</div>
                    <LineChart
											chartData={dataChart}
											labelsKey='Time'
											datasetsKey='Total'
											hasTooltip={true}
										/>
                </div>
                <div className='form-short'>
				<form className="form" onSubmit={handleSubmit(handleSubmitForm)}>
					<label className=''>
						Liên kết dài
						<div>
							<input type="text" name="URL" placeholder='Nhập liên kết dài' {...register("LongURL")} defaultValue= {data.LongURL} />
						</div>
						{errors.URL && <p>{errors.URL.message}</p>}
					</label>
					<label>Tùy chỉnh</label>
					<div className='custom-url'>
                        <div>
                            <input type="text" name="alias" placeholder='Liên kết rút gọn' defaultValue= {data.ShortURL} readOnly/>
                        </div>
					</div>
					<label>Nâng cao</label>
					<div className='custom-advanced'>
						<div>
							<input type="text" name="Title" placeholder='Tiêu đề meta'  {...register("Title")} defaultValue= {data.Title}/>
						</div>
						<div>
							<input type="text" name="Description"  placeholder='Mô tả meta'  {...register("Description")} defaultValue= {data.Description}/>
						</div>
						<div className='file-upload'>
							<input id="upload" ref="upload" type="file" accept="image/*" {...register("file")} onChange={hanldeFile}  className='fileInput'/>
                            <label>Chọn ảnh meta</label>
						</div>
					</div>
					<input type="submit" value="Cập nhật" className="submit-btn" />
				</form>
		    </div>
        </div>
            
	</div>
	)
}