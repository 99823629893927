import "../login/login.scss";
import logo from "../../assets/logo3 (1).png";
import logoGoogle from "../../assets/logogoogle.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useContext } from 'react';
import YupPassword from 'yup-password';
import AppContext from '../context/AppContext';
import { registerUser } from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
YupPassword(yup) // extend yup

export default function RegisterComponent() {
    const navigate = useNavigate();
    const state = useContext(AppContext);
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("Vui lòng nhập đúng định dạng email.")
            .required("Vui lòng nhập email."),
        fullName: yup
            .string()
            .required("Vui lòng nhập nhập tên."),
        password: yup
            .string()
            .min(8,'Mật khẩu phải có ít nhất 8 kí tự')
            .minLowercase(1, 'Mật khẩu phải có ít nhất 1 chữ thường')
            .minUppercase(1, 'Mật khẩu phải có ít nhất 1 chữ hoa')
            .minNumbers(1, 'Mật khẩu phải có ít nhất 1 số')
            .minSymbols(1, 'Mật khẩu phải có ít nhất 1 kí tự đặc biệt')
    });


    const form = useForm({
        resolver: yupResolver(schema),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = form;

    const handleSubmitForm = (data) => {
        if(data){
            registerUser(data).then(()=>{
                navigate("/otp");
                state.setRegister(data);
            }).catch(err => {
                toast.error(err.response.data.description,{hideProgressBar: true})
            });
        }
    };

	return (
		<div className="login_container">
            <ToastContainer />
			<div className="login_content">
                <Link to={`/`}>
				<img className="image_logo" src={logo} alt="Logo" />
				</Link>
				<div className="welcom_text">
				Chào mừng bạn đến với RutGonLink
				</div>
				<form className="form" onSubmit={handleSubmit(handleSubmitForm)}>
            <label>
   				 	Họ tên
						<div>
							<input type="text" name="fullName" {...register("fullName")} />
						</div>
            {errors.fullName && <p>{errors.fullName.message}</p>}
  				  </label>
						<label>
							Email
								<div>
								<input type="text" name="email" {...register("email")} />
								</div>
								{errors.email && <p>{errors.email.message}</p>}
							</label>
						<label>
   				 	Mật khẩu
						<div>
							<input type="password" name="password" {...register("password")}/>
						</div>
                {errors.password && <p>{errors.password.message}</p>}
  				  </label>
            <input type="submit" value="Tiếp theo" className="yellow_button"/>
				</form>
				<div className="or_text">
					<div className="line"></div>
					<div className="text">hoặc</div>
					<div className="line"></div>
				</div>

				<button className="google_button">
					<img className="image_logo_google" src={logoGoogle} alt="Logo" />
					<div>
						Tiếp tục với Google
					</div>
				</button>
			</div>
		</div>
	)
}