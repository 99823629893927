import './App.scss';
import DefaultLayoutComponent from '../Layout/DefaultLayout';
import { BrowserRouter as Router, Routes ,Route} from 'react-router-dom';
import { publicRoutes } from '../routes';
import { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect} from 'react';


function App() {

    useEffect(() => {
        document.title = 'RUTGONLINK';
      }, []);
  return (
		<Router>
				<div className="App">
					<Routes>
						{publicRoutes.map((route, index) =>{
							const Layout = route.layout === null ? Fragment : DefaultLayoutComponent
							const Page = route.component
							return <Route key={index} path={route.path} element={
								<Layout>
									<Page/>
								</Layout>
							}/>
						})}
					</Routes>
				</div>
		</Router>
  );
}

export default App;
