import Http from "./Http"; 
import { API_URL } from './ApiUrl';


export const registerUser = (data)=>{
    return Http.post(`${API_URL}user/create`, data);
}

export const registerUserOtp = (data)=>{
    return Http.post(`${API_URL}user/create`, data);
}

export const loginUser = (data)=>{
	return Http.post(`${API_URL}user/login`, data);
}

export const googleLogin = (token)=>{
	return Http.post(`${API_URL}auth/google`, {token: token});
}

export const postUrl = (data)=>{
	return Http.post(`${API_URL}url`,data);
}

export const getDomain = (data)=>{
    return Http.get(`${API_URL}Domain/list`);
}

export const getUrl = (params)=>{
    return Http.get(`${API_URL}url/list`,{params:{limit: params.limit,page: params.page}});
}

export const refreshToken = (refreshToken)=>{
    return Http.post(`${API_URL}url`,{token: refreshToken});
}

export const getUrlDetail = (id)=>{
    return Http.get(`${API_URL}url/${id}`);
}

export const putUrl = (data, id)=>{
	return Http.put(`${API_URL}url/${id}`,data);
}

