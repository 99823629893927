import Marquee from "react-fast-marquee";
import React, {useEffect , useState} from 'react';
import { getTokens, getUser } from '../../../services/Http';
import { io } from 'socket.io-client';
import '../fast-marquee/index.scss'


export default function FastMarqueeComponent() {
    const [value, setValue] = useState('');
    const user = getUser();
    const token = getTokens();
    const socket = io('https://api.tin247.website', {
        autoConnect: false,
          extraHeaders: {
              token: 'Bearer ' + `${token.accessToken}`
          }
    });

    const getData = () =>{
        socket.on(user.userId + '_URLNow', (url) => {
            const listUrlString = url.map(item => item.ShortURL+': ' + item.Total)
            setValue(listUrlString.join(' - '));
        })
    }

    function connect() {
        socket.connect();
    }

    useEffect(() => {
		connect();
	}, []);

    useEffect(() => {
		getData();
	}, [value]);
    
    return (
        <div className='fast-marquee'>
            <Marquee>
                {value}
            </Marquee>
        </div>
    )
}