export const CHART_OPTIONS = {
  backgroundColor:'#2151f5',
  animation: {
    duration: 0,
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  responsive: true,
  scales: {
    x: {
      display: true,
      grid: {
        display: true,
      },
    },
    y: {
      beginAtZero: true,
      display: true,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};
