
import HomeComponent from "../pages/home"
import LoginComponent from "../pages/login"
import RegisterComponent from "../pages/register"
import OTPComponent from "../pages/otp"
import UserComponent from "../pages/user"
import LinksComponent from "../pages/links"
import LinkDetailComponent from "../pages/link-detail"

// Public Routes
const publicRoutes =[
	{ path: '', component: HomeComponent , layout: null},
	{ path: 'login', component: LoginComponent , layout: null},
    { path: 'register', component: RegisterComponent, layout: null},
    { path: 'otp', component: OTPComponent, layout: null},
	{ path: 'user', component: UserComponent},
	{ path: 'links', component: LinksComponent},
	{ path: 'links/:linkId', component: LinkDetailComponent, layout: null }
]
const privateRoutes ={
	
}
export {publicRoutes, privateRoutes} 