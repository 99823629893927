import "../login/login.scss";
import logo from "../../assets/logo3 (1).png";
import logoGoogle from "../../assets/logogoogle.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from 'yup-password';
import { useNavigate } from "react-router-dom";
import { googleLogin, loginUser } from "../../services/Api";
import AppContext from '../context/AppContext';
import React, {useContext } from 'react';
import { API_URL } from '../../services/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { Link } from 'react-router-dom';
import { getTokens } from '../../services/Http';


YupPassword(yup) // extend yup

export default function LoginComponent() {
	const navigate = useNavigate();
	const state = useContext(AppContext);
  const schema = yup.object().shape({
        email: yup
            .string()
            .email("Vui lòng nhập đúng định dạng email.")
            .required("Vui lòng nhập email."),
        password: yup
            .string()
            .min(8,'Mật khẩu phải có ít nhất 8 kí tự')
            .minLowercase(1, 'Mật khẩu phải có ít nhất 1 chữ thường')
            .minUppercase(1, 'Mật khẩu phải có ít nhất 1 chữ hoa')
            .minNumbers(1, 'Mật khẩu phải có ít nhất 1 số')
            .minSymbols(1, 'Mật khẩu phải có ít nhất 1 kí tự đặc biệt')
  });


    const form = useForm({
        resolver: yupResolver(schema),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = form;
		
    const handleSubmitForm = async (data) => {
			if(data){
            loginUser(data).then((res)=>{
                localStorage.setItem('accessToken', res.accessToken);
                localStorage.setItem('refreshToken', res.refreshToken);
				localStorage.setItem('userId', res.data.userId);
                state.setUser((user) =>({ auth: true , refreshToken: res.refreshToken, accessToken: res.accessToken}));
                navigate('/user')
            })
            .catch((err) => {
                toast.error(err.response.data.description,{hideProgressBar: true})
            });
			}
		};

		// useGoogleOneTapLogin({
		// 	onSuccess: credentialResponse => {
		// 		googleLogin(credentialResponse.credential).then(res => {
		// 			localStorage.setItem('accessToken', res.accessToken);
		// 			localStorage.setItem('refreshToken', res.refreshToken);
		// 			localStorage.setItem('userId', res.data.userId);
		// 			state.setUser((user) =>({name:"huy", auth: true , refreshToken: res.refreshToken, accessToken: res.accessToken}));
		// 			navigate('/user')
		// 		})
		// 	},
		// 	onError: () => {
		// 		console.log('Login Failed');
		// 	},
		// });

		const loginGoogle = (credentialResponse) => {
			googleLogin(credentialResponse.credential).then(res => {
							localStorage.setItem('accessToken', res.accessToken);
							localStorage.setItem('refreshToken', res.refreshToken);
							localStorage.setItem('userId', res.data.userId);
							state.setUser((user) =>({name:"huy", auth: true , refreshToken: res.refreshToken, accessToken: res.accessToken}));
							navigate('/user')
						})
		}
	return (
		<div className="login_container">
      <ToastContainer />
			<div className="login_content">
				<Link to={`/`}>
				<img className="image_logo" src={logo} alt="Logo" />
				</Link>
				<div className="welcom_text">
				Chào mừng bạn đến với RutGonLink
				</div>
				<form className="form" onSubmit={handleSubmit(handleSubmitForm)}>
					<label class>
   				 	Email
    				<div>
							<input type="text" name="email" {...register("email")}/>
						</div>
						{errors.email && <p>{errors.email.message}</p>}
  				</label>
					<label>
   				 	Mật khẩu
						<div>
							<input type="password" name="password" {...register("password")} />
						</div>
						{errors.password && <p>{errors.password.message}</p>}
  				</label>
					<input type="submit" value="Tiếp theo" className="yellow_button"/>
				</form>
				{/* <div className="commit_text">
						Thông qua việc tạo một tài khoản, tôi đồng ý với
					<div className="text_decoration">
						&nbsp;Điều khoản dịch vụ&nbsp;
					</div>
						Và
					<div className="text_decoration">
					&nbsp;Chính sách quyền riêng tư&nbsp;
					</div>
						của Binance.
				</div> */}

				<div className="or_text">
					<div className="line"></div>
					<div className="text">hoặc</div>
					<div className="line"></div>
				</div>
				<GoogleLogin width="340px" onSuccess = {(credentialResponse) => loginGoogle(credentialResponse)}/>
				{/* <button className="google_button" onClick={loginGoogle}>
					<img className="image_logo_google" src={logoGoogle} alt="Logo" />
					<div>credentialResponse 
						Tiếp tục với Google
					</div>
				</button> */}
			</div>
		</div>
	)
}