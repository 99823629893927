
import SidebarComponent from "./SideBar/index";
import FastMarqueeComponent from "./fast-marquee/index";
import '../DefaultLayout/index.scss';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getTokens } from '../../services/Http';


export default function DefaultLayoutComponent({ children }) {
    const navigate = useNavigate();

    useEffect(() => {
        const token = getTokens();
        if(!token.accessToken){
            navigate('/')
        }
    }, []);
    return ( 
			<div className="app-content">
                <SidebarComponent/>
				<div className="wrapper">
					<FastMarqueeComponent/>  
          <div className='content'>
              {children}
          </div>
				</div>
			</div>
    )
}