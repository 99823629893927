import '../SideBar/index.scss';
import logo from "../../../assets/logo3 (1).png";
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import AppContext from '../../../pages/context/AppContext';
import React, {useContext } from 'react';

export default function SidebarComponent() {
    const location = useLocation();
	const state = useContext(AppContext);
		const logOut = () => {
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("accessToken");
			localStorage.removeItem('userId');
			state.setUser((user) =>({ auth: false , refreshToken: '', accessToken: ''}));
		}
    return (
        <div className='sidebar'>
            <img className="image_logo" src={logo} alt="Logo"/>
            <div className='list-item'>
                <div className='component'>
									<Link to='/user'>
											<div className= {location.pathname === '/user' ? 'item-activate' : 'item'}>
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sliders align-middle"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
													<span>Bảng điều khiển</span>
											</div>
									</Link>
									<Link to='/links'>
											<div className= {location.pathname === '/links' ? 'item-activate' : 'item'}>
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link align-middle"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
													<span>Danh sách links</span>
											</div>   
									</Link>
								</div>
								<Link to='/' onClick={logOut}>
											<div className= 'item'>
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out align-middle me-1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
													<span>Đăng xuất</span>
											</div>   
								</Link>
            </div>
        </div>
    )
}