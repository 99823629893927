import React, { createContext, useState } from 'react'
const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const [register, setRegister] = useState({});
  const [user , setUser] = useState({auth: false, accessToken:'', refreshToken:''});
	const loginContext = ((accessToken,refreshToken, auth) =>{
		setUser((user) => ({
			accessToken: accessToken,
			refreshToken: refreshToken,
			auth: true,
		}))
		localStorage.setItem('accessToken',accessToken);
		localStorage.setItem('refreshToken',refreshToken);	
	})
     
  return (
    <AppContext.Provider value={{ register, setRegister, user ,setUser ,loginContext}}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext;