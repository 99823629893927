import '../user/index.scss';
import { getDomain , postUrl, getUrl } from '../../services/Api';
import React, {useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Moment from 'react-moment';
// import { socket } from '../../socket';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getTokens, getUser } from '../../services/Http';
import { LineChart } from '../line-chart/index.js';
import { BarChart } from '../bar-chart/index.js';
import { io } from 'socket.io-client';


export default function UserComponent() {

	const [dataChart, setDataChart] = useState([]);
	const [curTotalClick, setCurTotalClick] = useState(0);
	const [totalClick, setTotalClick] = useState(0);
	const [totalLink, setTotalLink] = useState(0);
	const [curToltalLink, setCurTotalLink] = useState(0);
	const [image, setImage] = useState();
    const [listDomain, setListDomain] = useState([]);
    const [domain, setDomain] = useState('');
    const [listLinks, setListLinks]= useState([]);
    const [url, setUrl]= useState({});
    const [isShortUrl, setIsShortUrl] = useState(false);
    const [ fileName, setFileName ] = useState('');

	const user = getUser();
    const token = getTokens();
    const socket = io('https://api.tin247.website', {
        autoConnect: false,
          extraHeaders: {
              token: 'Bearer ' + `${token.accessToken}`
          }
    });
	const schema = yup.object().shape({
		URL: yup  
			.string()      
			.max(2048)
			.matches(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/, 
						'URL không đúng định dạng')  
			.required(),
		domain: yup
            .string() ,
		alias: yup
			  .string()
				.trim().max(200)
				.test(val => val.indexOf('/') < 0 || !val),
		Title: yup.string().max(200),
		Description: yup.string().max(200),
		});

	const form = useForm({
		resolver: yupResolver(schema),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
        reset
	} = form;
    

    const getListDomain =() =>{
        getDomain().then(res => {
            setListDomain(res);
            setDomain(res[0].URL);
        }).catch(err => console.log(err))
    }
    
    const getListUrl =() =>{
        getUrl({limit:6, page:1}).then(res => {
            setListLinks(res.data);
        }).catch(err => console.log(err));
			const date = new Date();
			const startDate = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
			const endDate = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');
			socket.emit(user.userId + '_TotalURLNow', { startDate:startDate, endDate:endDate});
			socket.on(user.userId + '_TotalURLNow', (data) => {
				setTotalLink(data.Total);
			})
			socket.emit(user.userId + '_TotalURLNow', {now: true});
			socket.on(user.userId + '_TotalURLNow', (data) => {
				setCurTotalLink(data.Total);
			})		
    }
    
		function connect() {
            socket.connect();
		}

		function disconnect() {
			socket.disconnect();
		}

		const getGetDataChart = () =>{
			const date = new Date();
			const currentDate = moment(date).date();
			const startDate = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
			const endDate = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');
			let listData;
			let total= 0;
			socket.emit(user.userId + '_totalClick', { startDate:startDate, endDate:endDate});
			socket.on(user.userId + '_totalClick', (data) => {
				listData = data.map(item => ({ ...item, Date: moment(item.Date).date()}));
				listData.forEach(element => {
					total += +element.Total;
					if(element.Date === currentDate){
						setCurTotalClick(element.Total)
					}
				});
				setDataChart(listData);
				setTotalClick(total);
			})
			socket.emit(user.userId + '_clickNow');
			socket.on(user.userId + '_clickNow', (click) => {
				total = 0;
				let totalClick = {...click, Date: moment(click.Date).date()};
				let chartData = listData.map(item => item.Date === totalClick.Date ? {...item,Total: totalClick.Total} : item);
				chartData.forEach(item => {
					total += +item.Total;
				})
				setTotalClick(total);
				setDataChart(chartData);
				setCurTotalClick(click.Total);
			})
		}

	useEffect(() => {
        getListDomain();
        getListUrl();
	}, []);

	useEffect(() => {
		connect();
		return () => {
            disconnect();
        };
	}, []);

	useEffect(() => {
		getGetDataChart();
	}, []);

	const hanldeFile = (e) => {
		setImage(e.target.files[0]);
        setFileName(e.target.files[0].name);
	}

	// const getBase64 = async (file) => {
	// 	return new Promise((resolve, reject) => {
	// 		const reader = new FileReader()
	// 		reader.readAsDataURL(file)
	// 		reader.onload = () => {
	// 			resolve(reader.result)
	// 		}
	// 		reader.onerror = reject
	// 	})
	// }
    
    const createNewUrl = () => {
        setIsShortUrl(false);
    }

    const copyUrl = (id) => {
        let copyText = document.getElementById(`${id}short`);
        let textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        toast.success('Sao chép thành công',{hideProgressBar: true})
    }

	const handleSubmitForm = async (data) =>{
        delete data.file;
        const formData = new FormData();
        let body = {...data, domain: domain};
        for (const key in body) {
            formData.append(key, body[key]);
        }
        formData.append('image', image);
        setFileName('');
        setImage(null);
        // if(image){
        //     await getBase64(image) // `file` your img file
		// 		.then(res =>{
		// 			let imageBase64 = (res).split(',')[1];
		// 			let binary = atob(imageBase64);
        //             body= {...data, image: binary, domain: domain};
                    
		// 		}) // `res` base64 of img file
		// 		.catch(err => {
        //             console.log(err);
        //         }) 
        // }else{
        //     body = {...data, domain: domain};;
        // }   
        postUrl(formData).then(res => {
            setIsShortUrl(true);
            setUrl(res);
            reset();
            toast.success("Tạo liên kết thành công!",{hideProgressBar: true});
            getListUrl();
        })
        .catch(err => toast.error(err.response.data.message,{hideProgressBar: true}));
	}

    const LinkItem = ({link}) => (
        <div className='link'>
					<Link to={`/links/${link.id}`}>
					<div className='url-title'>
                <strong>{link.Description}</strong>
                <span className='count-redirect'>
                                Lượt truy cập: {link.CountRedirect}
                </span>
                <small>
                    <Moment format="DD/MM/YYYY">
                        {link.RecordModified}
                    </Moment>
                </small>
            </div>
            <div className='url-infor'>
                <div className='long-url'>
                    <img src={'https://s2.googleusercontent.com/s2/favicons?domain='+link.LongURL} alt="Logo" />
                    <span>
                        {link.LongURL}
                    </span>
                </div>
                <div className='short-url'>
                    <span id={`${link.id}short`}>{link.ShortURL}</span>
                </div>
                <div className='detail-btn'>
                            <span>
                                Xem chi tiết
                            </span>
                </div>
            </div>
			</Link>
            <div className='copyboard' onClick={() =>copyUrl(link.id)}>
                        <svg width="20px" height="20px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                                    fill="#FFFFFF"
                                />
                        </svg>
            </div>
        </div>
    )

    const Form = () => (
        <div className='form-short'>
						<form className="form" onSubmit={handleSubmit(handleSubmitForm)}>
							<label className=''>
								Liên kết dài
								<div>
									<input type="text" name="URL" placeholder='Nhập liên kết dài' {...register("URL")}/>
								</div>
								{errors.URL && <p>{errors.URL.message}</p>}
							</label>
							<label>Tùy chỉnh</label>
							<div className='custom-url'>
									<div className='select-box'>
									    <select onChange={(e) => setDomain(e.target.value)} value={domain}>
												{listDomain.map(item => 
													<option key={item.id} value={item.URL}>{item.URL}</option>
												)}
									    </select>                                  
									</div>
									<div>
										<input type="text" name="alias" placeholder='Liên kết rút gọn' {...register("alias")}/>
									</div>
							</div>
							<label>Nâng cao</label>
							<div className='custom-advanced'>
									<div>
										<input type="text" name="Title" placeholder='Tiêu đề meta'  {...register("Title")}/>
									</div>
									<div>
										<input type="text" name="Description"  placeholder='Mô tả meta'  {...register("Description")}/>
									</div>
									<div className='file-upload'>
										<input id="upload" ref="upload" type="file" accept="image/*" {...register("file")} onChange={hanldeFile}  className='fileInput'/>
                                        {
                                            fileName ? <label style={{color: "white"}}>{fileName }</label> : <label>Chọn ảnh meta</label> 
                                        }
									</div>
							</div>
							<input type="submit" value="Tạo liên kết rút gọn" className="yellow_button" />
						</form>
		</div>
    )

    const copyUrlShort = () => {
        let copyText = document.getElementById(`short`);
        let textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        toast.success('Sao chép thành công',{hideProgressBar: true})
    }

    const FormShort = () => (
        <div className='form-after'>
            <div className='url-item'>
                <span>Liên kết dài</span>
                <a className='url'>
                    {url.URL}
                </a>
            </div>
            <div className='url-item'>
                <span>Liên kết rút gọn</span>
                <a className='url' id='short'>
                    {url.url}
                </a>
            </div>
            <button onClick={copyUrlShort} className='copy-btn'>Sao chép liên kết</button>
            <button onClick={createNewUrl}>Tạo liên kết khác</button>
        </div>
    )

	return (
		<div className='wrap-user'>
            <ToastContainer/>
			<div className='container-content'>
			<div className='list'>
				<div className='list-link p-4'>
						<div className='header-link pb-1'>Links</div>
						<div className='number p-1'>{totalLink}</div>
						<div className='number-today p-1'>+{curToltalLink} Hôm nay</div>
				</div>
				<div className='list-link p-4'>
						<div className='header-link pb-1'>Lượt truy cập</div>
						<div className='number p-1'>{totalClick}</div>
						<div className='number-today p-1'>+{curTotalClick} Hôm nay</div>
				</div>
			</div>
			<div className='chart-content'>
				<div className='px-4 header-label'>Truy cập gần đây</div>
					<BarChart
						chartData={dataChart}
						labelsKey='Date'
						datasetsKey='Total'
						hasTooltip={true}
					/>
					{/* <BarChart
						height={300}
						margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
						grid={{ vertical: true, horizontal: true }}
						dataset={dataChart}
						xAxis={[{ scaleType: 'band', dataKey: 'Date' }]}
						series={[{ dataKey: 'Total' }]}
					/> */}
				</div>	
			</div>
			<div className='activity'>
                    { !isShortUrl ? <Form/> : <FormShort/>}
					<div className='list-activity'>
						<div className='title'>Danh sách liên kết gần đây</div>
                        <div className='list-links'>
                           {listLinks.map(item => (
                                <LinkItem
                                key= {item.id}
                                link ={item}
                            />
                           ))}                 
                        </div>
                <div className='activate-item'>
                  <div className='infor'>

                  </div>
                  <div className='location'>

                  </div>
            </div>
					</div>	
			</div>
		</div>
	)
}
